<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        to="https://fastinvoice.me"
        class="brand-logo"
      >
        <vuexy-logo
          style="width:80px;"
        />
        <h2 class="brand-text text-primary ml-1">
          Fast Invoice
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-overlay
            id="overlay-background"
            :show="pageIsLoading"
            variant="light"
            opacity="0.85"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <b-card

                class="text-center"
              >

                <h2>
                  <b-spinner

                    class="mr-1"
                    label="Loading"
                  />
                  {{ overlayMsg }}
                </h2>

              </b-card>
            </template>

            <b-card-text class="mb-2" />
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Welcome to Fast Invoice! 👋 Dev66
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account
            </b-card-text>
            <b-alert
              v-if="errorData"
              variant="danger"
              show
            >
              <h4 class="alert-heading">
                {{ errorData.message }}
              </h4>
              <div
                v-if="errorData.errors"
                class="alert-body"
              >
                <div
                  v-for="(v, k) in errorData.errors"
                  :key="k"
                >
                  <span
                    v-for="error in v"
                    :key="error"

                    style="display: inline-block;"
                  ><strong>*</strong>{{ error }}</span>

                </div>

              </div>
            </b-alert>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="E-Mail"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                      @blur="handleBlur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group />

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('Sign In') }}

                </b-button>

              </b-form>
            </validation-observer>

            <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div>

            <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>

            <!-- social buttonss -->
            <div
              v-if="true==false"
              class="d-flex justify-content-center"
            >

              <b-button
                href="javascript:void(0)"
                variant="google"
                block
                @click="socialLogin('google')"
              >
                <feather-icon icon="MailIcon" />
                {{ $t('Sign In With Google') }}
              </b-button>
            </div>
          </b-overlay>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BOverlay,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BSpinner,
  BButton,
  VBTooltip,
  BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Providers } from 'universal-social-auth'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BOverlay,
    BFormGroup,
    BSpinner,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    BAlert,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      pageIsLoading: false,
      overlayMsg: 'Loading...',
      errorData: null,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,

      // social auth
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
      // end social auth
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleBlur() {
      this.userEmail = this.userEmail.trim()
    },
    // social auth
    socialLogin(authType) {
      this.useAuthProvider(authType, null)
    },

    useAuthProvider(provider, proData) {
      const pro = proData
      const ProData = pro || Providers[provider]
      this.$Oauth.authenticate(provider, ProData).then(response => {
        const rsp = response
        if (rsp.code) {
          this.responseData.code = rsp.code
          this.responseData.provider = provider
          this.useSocialLogin()
        }
      // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        alert('Provider login error please login with username and password')
      })
    },
    useSocialLogin() {
    // otp from input Otp form
    // hash user data in your backend with Cache or save to database
      const pdata = { code: this.responseData.code, otp: this.data.tok, hash: this.hash }
      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/social-login/${this.responseData.provider}`, pdata).then(async response => {
      // `response` data base on your backend config
        if (response.data.status === 444) {
          this.hash = response.data.hash
          this.fauth = true // Option show Otp form incase you using 2fa or any addition security apply to your app you can handle all that from here
        } else if (response.data.status === 445) {
        // do something Optional
        } else {
          this.handleLoginSuccessResponse(response)
        }
      }).catch(error => {
        this.pageIsLoading = false
        //   console.log(error.response.data.error)
        this.errorData = error.response.data
        this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    // eslint-disable-next-line no-unused-vars
    async  useLoginFirst(e) {
    // this sample of to pust user data to my store
      // console.log('userdata', e)
    },
    // social login methods

    handleLoginSuccessResponse(response) {
      const userData = response.data.data.user_data
      useJwt.setToken(response.data.data.token)
      useJwt.setTokenExpiry(response.data.data.expires_in)
      //   useJwt.setRefreshToken(response.data.refreshToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      store.commit('app/UPDATE_USER_DATA', userData)
      this.$ability.update(userData.ability)

      // ? This is just for demo purpose as well.
      // ? Because we are showing eCommerce app's cart items count in navbar

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router
        .replace(getHomeRouteForLoggedInUser(userData.role))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Welcome',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully logged in.',
            },
          })
        })
      this.pageIsLoading = false
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.pageIsLoading = true
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              this.handleLoginSuccessResponse(response)
            })
            .catch(error => {
              this.pageIsLoading = false
              //   console.log(error.response.data.error)
              this.errorData = error.response.data
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
